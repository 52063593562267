// @flow
import React from 'react';
import icons from '../../../app/Resources/images/icons.svg';

type IconProps = {|
    name: string,
    additionalClassName?: string
|};

export default function Icon(props: IconProps) {
    return (
        <svg
            className={'icon icon--' + props.name + ' ' + (props.additionalClassName || '')}
            width="16"
            height="16"
            aria-hidden="true"
        >
            <use xlinkHref={icons + '#' + props.name} />
        </svg>
    );
}
