// @flow
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const removeCookieFromCurrentDomainAndAncestors = (cookieName: string) => {
    const domainParts = window.location.hostname.split('.');

    while (domainParts.length > 0) {
        cookies.remove(cookieName, {
            path: '/',
            domain: domainParts.join('.'),
        });
        domainParts.shift();
    }

    cookies.remove(cookieName, { path: '/' });
};

export default removeCookieFromCurrentDomainAndAncestors;