// @flow
import React, { type Element, type Node } from 'react';
import Translator from '../utilities/translatorGlobal';
import _ from 'lodash';

export type HtmlString = Element<typeof HtmlWrapper>
    | Element<typeof Trans>
    | Element<typeof TransChoice>
    | string;

type TransProps = {|
    children: string,
    parameters?: Object,
    domain?: string,
    locale?: string
|};

type TransChoiceProps = {|
    children: string,
    number: number,
    parameters?: Object,
    domain?: string,
    locale?: string
|};

export function Trans({
    children,
    parameters,
    domain,
    locale,
}: TransProps): Element<typeof HtmlWrapper> {
    return (
        <HtmlWrapper>{Translator.trans(children, escapeParameters(parameters), domain, locale)}</HtmlWrapper>
    );
}

export function TransChoice({
    children,
    number,
    parameters,
    domain,
    locale,
}: TransChoiceProps): Element<typeof HtmlWrapper> {
    return (
        <HtmlWrapper>{Translator.transChoice(children, number, escapeParameters(parameters), domain, locale)}</HtmlWrapper>
    );
}

function escapeParameters(parameters) {
    if (parameters !== undefined) {
        parameters = _.mapValues(parameters, _.escape);
    }

    return parameters;
}

interface HtmlWrapperProps {
    children?: Node
}

const HtmlWrapper = (props: HtmlWrapperProps) => {
    // We use HTML markup in translations and therefore we do not want to escape them.
    // Using "dangerouslySetInnerHTML" is OK here because translation messages are provided by us and cannot
    // contain malicious input.
    // On the other hand, message parameters can contain user input and therefore parameters are escaped.
    // Unfortunately, we must wrap the message inside some element because React.Fragment
    // does not support dangerouslySetInnerHTML property.
    // See: https://github.com/facebook/react/issues/12014
    return (
        <span dangerouslySetInnerHTML={{ __html: props.children }} />
    );
};
