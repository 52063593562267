// @flow
import LmcCookieConsentManager from '@lmc-eu/cookie-consent-manager';
import Translator from '../utilities/translatorGlobal';
import { startsWith } from '../utilities/stringUtils';
import Cookies from 'universal-cookie';
import removeCookieFromCurrentDomainAndAncestors from './removeCookieFromCurrentDomainAndAncestors';
import logger from '../utilities/logger';
import type { CookiesConsentCategories } from '../utilities/cookiesConsentCategories';

const cookies = new Cookies();

const removeCookiesNotMatchingPrefixes = (prefixes: string[]) => {
    Object.keys(cookies.getAll())
        .filter(savedCookieName => {
            return !prefixes.some(prefix => startsWith(savedCookieName, prefix));
        })
        .forEach(savedCookieWithoutMatchingPrefix => removeCookieFromCurrentDomainAndAncestors(savedCookieWithoutMatchingPrefix));
};

const removeBrowserStorageKeysNotMatchingPrefixes = (storage: Storage, prefixes: string[]) => {
    Object.keys(storage)
        .filter(savedKey => {
            return !prefixes.some(prefix => startsWith(savedKey, prefix));
        })
        .forEach(savedKeyWithoutMatchingPrefix => storage.removeItem(savedKeyWithoutMatchingPrefix));
};

const clearAllStoragesIfNoConsentWasGivenYet = () => {
    removeCookiesNotMatchingPrefixes([]);
    localStorage.clear();
    sessionStorage.clear();
};

export const initLmcCookiesConsentManager = (cookieNamesByCategory: CookiesConsentCategories) => {
    const getAllKnownCookiePrefixes = () => {
        let allKnownCookiePrefixes = [];
        Object.keys(cookieNamesByCategory).forEach(categoryName => {
            allKnownCookiePrefixes = allKnownCookiePrefixes.concat(cookieNamesByCategory[categoryName]);
        });

        return allKnownCookiePrefixes;
    };

    const logCookiesWithUnknownPrefixes = () => {
        const allKnownCookiePrefixes = getAllKnownCookiePrefixes();

        const cookiesWithoutKnownPrefix = Object.keys(cookies.getAll())
            .filter(savedCookieName => {
                return !allKnownCookiePrefixes.some(knownCookiePrefix => startsWith(savedCookieName, knownCookiePrefix));
            });

        if (cookiesWithoutKnownPrefix.length > 0) {
            logger.error('Found unknown cookies: "' + cookiesWithoutKnownPrefix.join('", "') + '". These will be deleted.');
        }
    };

    const logBrowserStorageKeysWithUnknownPrefixes = (storage: Storage, storageName: string) => {
        const allKnownCookiePrefixes = getAllKnownCookiePrefixes();

        const storageKeysWithoutKnownPrefix = Object.keys(storage)
            .filter(savedCookieName => {
                return !allKnownCookiePrefixes.some(knownCookiePrefix => startsWith(savedCookieName, knownCookiePrefix));
            });

        if (storageKeysWithoutKnownPrefix.length > 0) {
            logger.error('Found unknown keys in ' + storageName + ': "' + storageKeysWithoutKnownPrefix.join('", "') + '". These will be deleted.');
        }
    };

    const handleConsentChange = (cookieConsentObject) => {
        if (cookieConsentObject.getUserPreferences().accept_type === 'all') {
            return;
        }

        const approvedConsentLevels = cookieConsentObject.get('level');

        let approvedCookiePrefixes = [];

        approvedConsentLevels.forEach(approvedConsentLevelName => {
            if (cookieNamesByCategory.hasOwnProperty(approvedConsentLevelName)) {
                approvedCookiePrefixes = approvedCookiePrefixes.concat(cookieNamesByCategory[approvedConsentLevelName]);
            }
        });

        logCookiesWithUnknownPrefixes();
        removeCookiesNotMatchingPrefixes(approvedCookiePrefixes);

        logBrowserStorageKeysWithUnknownPrefixes(localStorage, 'localStorage');
        removeBrowserStorageKeysNotMatchingPrefixes(localStorage, approvedCookiePrefixes);

        logBrowserStorageKeysWithUnknownPrefixes(sessionStorage, 'sessionStorage');
        removeBrowserStorageKeysNotMatchingPrefixes(sessionStorage, approvedCookiePrefixes);
    };

    const lmcCookieConsentManager = LmcCookieConsentManager(
        'atmoskop' + '.' + Translator.locale,
        {
            defaultLang: Translator.locale,
            autodetectLang: false,
            onAccept: handleConsentChange,
            onChange: handleConsentChange,
            config: {
                hide_from_bots: ((navigator: any).webdriver) !== true,
                cookie_necessary_only_expiration: 30,
            },
            displayMode: 'force',
            secondaryButtonMode: 'acceptNecessary',
            translationOverrides: {
                cs: {
                    consentTitle: 'Cookies vám lépe pomohou najít práci ve firmě, která vám sedne',
                },
            },
        },
    );

    if (lmcCookieConsentManager.getUserPreferences().accepted_categories.length === 0) {
        clearAllStoragesIfNoConsentWasGivenYet();
    }
};
