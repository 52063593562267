// @flow
declare var dataLayer: Object;

export default function pushGANonInteractionEvent(
    category: string,
    action: string,
    label: string,
    value: number = 0,
) {
    dataLayer.push({
        'event': 'ga.event',
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label,
        // defaults are values from previous events unless overwritten
        'eventValue': value,
        'eventNonInteraction': false,
    });
}
