// @flow
import axios from 'axios';
import routes from './routes';

const logger = {
    info: (message: string) => {
        // eslint-disable-next-line no-console
        console.log('Info: ' + message);
    },
    error: (message: string, errorData: ?any) => {
        // eslint-disable-next-line no-console
        console.error('Error: ' + message);
        logFrontendErrorToApi(message, errorData);
    },
};

function logFrontendErrorToApi(message: string, errorData: ?any) {
    axios.post(routes['api.logErrorFromFrontend'], {
        errorMessage: message,
        errorData: errorData,
    });
}

export default logger;

// see https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
export function handleUncaughtError(message: string, url: string, lineNumber: ?number, columnNumber: ?number, error: ?Object) {
    const errorStack = error?.stack;
    const errorData: Object = {
        url,
        lineNumber,
        columnNumber,
        error,
    };

    if (errorStack !== null) {
        errorData['errorStack'] = errorStack;
    }

    logFrontendErrorToApi(message, errorData);

    return false;
}
