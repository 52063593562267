// @flow
import CompanySearch, { type CompanySearchProps } from './CompanySearch';
import React from 'react';
import routes from '../utilities/routes';
import type { ProfileSearchSuggestionType } from './ProfileSearchSuggestionType';

function CompanySearchForSerp(props: CompanySearchProps) {
    const getSearchResultsPageUrl = (searchTerm: string): string => {
        return routes['companySearch.search'] + '?q=' + encodeURIComponent(searchTerm);
    };

    const onSubmit = (searchQuery: string, selectedResult: ?ProfileSearchSuggestionType) => {
        if (selectedResult) {
            window.location.href = selectedResult.profileUrl;
        } else if (searchQuery !== '') {
            window.location.href = getSearchResultsPageUrl(searchQuery);
        }
    };

    return <CompanySearch
        {...props}
        onSubmit={onSubmit}
    />;
}

CompanySearchForSerp.defaultProps = CompanySearch.defaultProps;

export default CompanySearchForSerp;
