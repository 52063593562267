// @flow

import type { AtmoskopGlobalVariableType } from '../baseTypes';

declare var atmoskop: AtmoskopGlobalVariableType;

export default function formatValueAsNumber(value: ?number): string {
    if (value === null || value === undefined) {
        return '';
    }

    return new Intl.NumberFormat(atmoskop.bcp47LanguageTagWithRegion, { maximumFractionDigits: 2 }).format(value);
}
