// @flow
import _ from 'lodash';
import React from 'react';
import Translator from '../utilities/translatorGlobal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import OutsideClickHandler from 'react-outside-click-handler';
import CompanySearchResults from './CompanySearchResults';
import type { ProfileSearchSuggestionType } from './ProfileSearchSuggestionType';
import keyCodes from '../utilities/keyCodes';
import routes from '../utilities/routes';
import Icon from '../utilities/icon';

export const NOTHING_SELECTED_RESULT_INDEX = -1;

export type CompanySearchProps = {|
    initialSearchQuery?: string,
    minimalLengthToSearch: number,
    isCollapsible: boolean,
    additionalClassNames: string,
    dropdownMenuAdditionalClassNames: string,
    rpsForMatejRecommendations: string,
    onInputChange: (searchQuery: string) => void,
    onSubmit: (searchQuery: string, selectedResult: ?ProfileSearchSuggestionType) => void,
    showMatejRecommendations: boolean
|}

type CompanySearchState = {|
    isFocused: boolean,
    searchQuery: string,
    isLoading: boolean,
    isLoadingRecommendations: boolean,
    results: ProfileSearchSuggestionType[],
    selectedResultIndex: number,
    recommendations: ProfileSearchSuggestionType[],
|}

export default class CompanySearch extends React.PureComponent<CompanySearchProps, CompanySearchState> {
    static defaultProps = {
        isCollapsible: true,
        additionalClassNames: '',
        dropdownMenuAdditionalClassNames: '',
        rpsForMatejRecommendations: '',
        onInputChange: (searchQuery: string) => {},
        onSubmit: (searchQuery: string, selectedResult: ?ProfileSearchSuggestionType) => {},
        showMatejRecommendations: true,
    };

    state = {
        isFocused: false,
        searchQuery: this.props.initialSearchQuery || '',
        isLoading: false,
        isLoadingRecommendations: false,
        results: [],
        selectedResultIndex: NOTHING_SELECTED_RESULT_INDEX,
        recommendations: [],
    };

    searchInput: ?HTMLInputElement;
    cookies = new Cookies();

    onSearchButtonClick = () => {
        if (this.searchInput) {
            this.searchInput.focus();
        }

        this.props.onSubmit(this.state.searchQuery, null);
        this.setState({ isFocused: true });
    };

    clearResults = () => {
        this.setState({
            results: [],
            isLoading: false,
            selectedResultIndex: NOTHING_SELECTED_RESULT_INDEX,
        });
    };

    handleInputFocus = () => {
        if (this.state.recommendations.length === 0 && this.state.isLoadingRecommendations === false && this.props.showMatejRecommendations) {
            this.loadRecommendationsFromMatej();
        }

        this.setState({ isFocused: true });
    };

    hideDropdown = () => {
        this.setState({ isFocused: false });
    };

    handleInputChange = () => {
        const searchInputValue = (!this.searchInput || !this.searchInput.value) ? '' : this.searchInput.value;

        this.props.onInputChange(searchInputValue);
        this.setState({ searchQuery: searchInputValue }, this.updateSearchResultsThrottled);
    };

    onClickOnResult = (profile: ProfileSearchSuggestionType) => {
        this.setState({
            searchQuery: profile.companyName,
            isFocused: false,
        });
        this.props.onInputChange(profile.companyName);
        this.props.onSubmit(profile.companyName, profile);
    }

    updateSearchResults = () => {
        if (this.state.searchQuery.length >= this.props.minimalLengthToSearch) {
            this.loadSearchResults();
        } else if (this.state.searchQuery.length === 0) {
            this.clearResults();
        }
    };

    updateSearchResultsThrottled = _.throttle(this.updateSearchResults, 500);

    getMaxSelectableIndex = () => {
        if (this.state.results.length > 0) {
            return this.state.results.length - 1;
        }

        return this.state.recommendations.length - 1;
    };

    handleKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
        switch (event.keyCode) {
            case keyCodes.KEY_UP:
                if (this.state.selectedResultIndex > 0) {
                    this.setState((prevState: CompanySearchState) => ({
                        selectedResultIndex: prevState.selectedResultIndex - 1,
                    }));
                }

                event.preventDefault();
                break;

            case keyCodes.KEY_DOWN:
                if (this.state.selectedResultIndex < this.getMaxSelectableIndex()) {
                    this.setState((prevState: CompanySearchState) => ({
                        selectedResultIndex: prevState.selectedResultIndex + 1,
                    }));
                }

                event.preventDefault();
                break;

            case keyCodes.KEY_ENTER:
                const selectedResultIndex = this.state.selectedResultIndex;
                const isShowingMatejSuggestions = this.state.searchQuery.length === 0 && this.props.showMatejRecommendations;

                if (isShowingMatejSuggestions && selectedResultIndex !== NOTHING_SELECTED_RESULT_INDEX) {
                    this.onClickOnResult(this.state.recommendations[selectedResultIndex]);
                }

                if (this.state.searchQuery.length > 0 && selectedResultIndex !== NOTHING_SELECTED_RESULT_INDEX) {
                    this.onClickOnResult(this.state.results[selectedResultIndex]);
                }

                if (this.state.searchQuery.length > 0 && selectedResultIndex === NOTHING_SELECTED_RESULT_INDEX) {
                    this.onSearchButtonClick();
                }

                event.preventDefault();
                break;
        }
    };

    getSearchClassName = () => {
        let searchClassName = 'search ' + this.props.additionalClassNames;

        if (this.state.isLoading) {
            searchClassName += ' search--loading';
        }

        if (this.props.isCollapsible && (this.state.isFocused || this.state.searchQuery !== '')) {
            searchClassName += ' search--open';
        }

        return searchClassName;
    };

    loadSearchResults = () => {
        this.setState({ isLoading: true });

        axios.get(`${routes['companySearch.searchWithReviews']}`, { params: { q: this.state.searchQuery } })
            .then(({ data }) => {
                this.setState({
                    results: data,
                    isLoading: false,
                    selectedResultIndex: NOTHING_SELECTED_RESULT_INDEX,
                });
            })
            .catch(({ error }) => {
                this.setState({
                    results: [],
                    isLoading: false,
                    selectedResultIndex: NOTHING_SELECTED_RESULT_INDEX,
                });
            });
    };

    loadRecommendationsFromMatej = () => {
        const lmcVisitorCookie = this.readValueOfLmcVisitorCookie();
        this.setState({ isLoadingRecommendations: true });

        let recommendationsEndpoint = `${routes['recommendation.interestingProfiles']}?lmcCookieId=` + encodeURIComponent(lmcVisitorCookie);
        recommendationsEndpoint += '&rps=' + encodeURIComponent(this.props.rpsForMatejRecommendations);
        if (window.profileUrlIdentifier !== undefined) {
            recommendationsEndpoint += '&currentProfileUrlIdentifier=' + encodeURIComponent(window.profileUrlIdentifier);
        }

        axios.get(recommendationsEndpoint)
            .then(({ data }) => {
                this.setState({
                    recommendations: data,
                    isLoadingRecommendations: false,
                });
            })
            .catch(({ error }) => {
                this.setState({ isLoadingRecommendations: false });
            });
    };

    getSearchResults = () => {
        if (this.state.results.length === 0 && this.state.searchQuery.length < this.props.minimalLengthToSearch) {
            return this.state.recommendations;
        }

        return this.state.results;
    };

    readValueOfLmcVisitorCookie = () => {
        return this.cookies.get('lmc_visitor');
    };

    getDropdownMenuHeadingText = () => {
        if (this.state.results.length === 0 && this.state.recommendations.length !== 0) {
            return Translator.trans('COMPANY_SEARCH_DROPDOWN_MENU_HEADING_TEXT');
        }

        return null;
    };

    render() {
        return (
            <div
                className="companySearch"
                data-cy="company-search-container"
            >
                <OutsideClickHandler
                    onOutsideClick={this.hideDropdown}
                >
                    <div
                        className={this.getSearchClassName()}
                    >
                        <input
                            type="search"
                            required={true}
                            value={this.state.searchQuery}
                            className="search__input"
                            placeholder={Translator.trans('COMPANY_SEARCH_PLACEHOLDER')}
                            onFocus={this.handleInputFocus}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                            ref={input => this.searchInput = input}
                            aria-label={Translator.trans('COMPANY_SEARCH_PLACEHOLDER')}
                        />
                        <button
                            className="search__action d-md-none"
                            id="js-main-search-form-button"
                            onClick={this.onSearchButtonClick}
                            type="button"
                        >
                            <Icon name="reactSearchIcon" />
                        </button>
                        <Icon name="reactSearchIcon" additionalClassName="d-none d-md-block" />
                    </div>
                    <CompanySearchResults
                        visible={this.state.isFocused}
                        results={this.getSearchResults()}
                        selectedResultIndex={this.state.selectedResultIndex}
                        dropdownMenuAdditionalClassNames={this.props.dropdownMenuAdditionalClassNames}
                        dropdownMenuHeadingText={this.getDropdownMenuHeadingText()}
                        onClickOnResult={this.onClickOnResult}
                    />
                </OutsideClickHandler>
            </div>
        );
    }
}
