// @flow
import React from 'react';
import Translator from '../utilities/translatorGlobal';
import type { ProfileSearchSuggestionType } from './ProfileSearchSuggestionType';
import formatValueAsNumber from '../utilities/formatValueAsNumber';

type CompanySearchResultsProps = {|
    visible: boolean,
    results: Array<ProfileSearchSuggestionType>,
    selectedResultIndex: number,
    dropdownMenuAdditionalClassNames: string,
    dropdownMenuHeadingText: ?string,
    onClickOnResult: (result: ProfileSearchSuggestionType) => void
|}

export default function CompanySearchResults(props: CompanySearchResultsProps) {
    const getDropdownMenuClassName = () => {
        let dropdownMenuClassName = 'dropdown-menu ' + props.dropdownMenuAdditionalClassNames;

        if (props.visible) {
            dropdownMenuClassName = dropdownMenuClassName + ' show';
        }

        return dropdownMenuClassName;
    };

    const onMouseClick = (event: SyntheticMouseEvent<HTMLLinkElement>, result: Object) => {
        event.preventDefault();
        event.stopPropagation();
        props.onClickOnResult(result);
    };

    if (props.results.length === 0) {
        return null;
    }

    const getDropdownMenuHeader = () => {
        if (props.dropdownMenuHeadingText === null) {
            return null;
        }

        return <h3 className="dropdown-header-redesigned text-wrap">{props.dropdownMenuHeadingText}</h3>;
    };

    const options = props.results.map((result, index) => {
        const numberOfResultsBlock = result.numberOfReviews > 0 ?
            <span className="search-dropdown__rating-count ml-auto pl-7">
                {Translator.trans('N_REVIEWS', {'count': result.numberOfReviews} )}
            </span>
            : null;

        return (
            <button
                key={result.id}
                onClick={(event) => onMouseClick(event, result)}
                className={'btn btn-link dropdown-item search-dropdown__item ' + (index === props.selectedResultIndex ? 'active' : '')}
                data-cy="dropdown-item"
            >
                <span className="d-flex align-items-baseline w-100">
                    <span className="search-dropdown__company-name" data-cy="company-search-dropdown-item-company-name">
                        {result.companyName}
                    </span>
                    {numberOfResultsBlock}
                </span>
            </button>
        );
    });

    return (
        <div className="dropdown search-dropdown">
            <div className={getDropdownMenuClassName()}>
                {getDropdownMenuHeader()}
                {options}
            </div>
        </div>
    );
}

CompanySearchResults.defaultProps = {
    dropdownMenuAdditionalClassNames: '',
};
