// @flow

export type Features = {
    links_to_questionnaire_with_profile_search: boolean,
    cookie_consent_bar: boolean,
    show_typeform_entry_point_missing_company: boolean,
    links_to_profile_gallery_on_profile: boolean,
    show_links_to_job_descriptions: boolean,
    show_recommendations_in_search: boolean,
};

// exports object defined in templates/scripts/features.html.twig
declare var features: Features;
features = window.features;
export default features;