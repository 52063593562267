// @flow
import React, { useState } from 'react';
import Modal from 'react-modal';
import features from '../utilities/features';
import Translator from '../utilities/translatorGlobal';
import type { AtmoskopGlobalVariableType } from '../baseTypes';
import { Trans } from '../components/Translator';
import icons from '../../../app/Resources/images/icons.svg';
import routes from '../utilities/routes';

declare var atmoskop: AtmoskopGlobalVariableType;
declare var dataLayer: Object;

type Props = {|
    email: string,
    isModalOpen: boolean,
|};

const customStyles = {
    overlay: {
        position: 'fixed',
        zIndex: 10000000000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
    },
};

const SubscribeToProfileNotificationsSuccessModal = (props: Props) => {
    const [openModal, setOpenModal] = useState<boolean>(props.isModalOpen);
    let subscribeToProfileNotificationsSuccessHeading, subscribeToProfileNotificationsSuccessHeadingIcon,
        weWillNotifyYouAboutNewsText, weWillNotifyYouAboutNewsText2;

    if (atmoskop.isUserLoggedIn) {
        subscribeToProfileNotificationsSuccessHeadingIcon =
            <svg
                className="icon icon--check-circle-outline text-success mr-3"
                width="16"
                height="16"
                aria-hidden="true"
            >
                <use href={icons + '#check-circle-outline'} />
            </svg>;
        subscribeToProfileNotificationsSuccessHeading =
            <strong>{Translator.trans('SUBSCRIBE_TO_PROFILE_NOTIFICATIONS_SUCCESS_HEADING')}</strong>;
        weWillNotifyYouAboutNewsText =
            <p className="mb-0 heavy-text">
                <Trans
                    domain="messages"
                    parameters={{ 'email': props.email }}
                >WE_WILL_NOTIFY_YOU_ABOUT_NEWS</Trans>
            </p>;
        weWillNotifyYouAboutNewsText2 =
            <p className="form-text text-muted">{Translator.trans('YOU_CAN_UNSUBSCRIBE_IN_YOUR_EMAIL')}</p>;
    } else {
        subscribeToProfileNotificationsSuccessHeadingIcon =
            <svg className="icon icon--envelope text-secondary mr-5" width="16" height="16" aria-hidden="true">
                <use href={icons + '#envelope'} />
            </svg>;
        subscribeToProfileNotificationsSuccessHeading =
            <strong>{Translator.trans('SUBSCRIBE_TO_PROFILE_NOTIFICATIONS_SUCCESS_HEADING_1')}</strong>;
        weWillNotifyYouAboutNewsText =
            <p className="mb-0 heavy-text">
                <Trans
                    domain="messages"
                    parameters={{ 'email': props.email }}
                >WE_WILL_NOTIFY_YOU_ABOUT_NEWS_1</Trans>
            </p>;
        weWillNotifyYouAboutNewsText2 = <p className="form-text text-muted">{Translator.trans('CHECK_SPAM_FOLDER')}</p>;
    }

    dataLayer.push({
        'event': 'user_login_sent-link-to-the-email',
        'eventCategory': 'user',
        'eventAction': 'user_login_sent-link-to-the-email',
        'eventLabel': '-',
        'eventValue': 0,
        'eventNonInteraction': false,
    });

    return (
        <Modal
            id="subscribe-to-profile-notifications-success-modal"
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            contentLabel="Subscribe to profile notifications success modal"
            style={customStyles}
            shouldFocusAfterRender={false}
            appElement={document.body}
        >
            <div className="modal-content">
                <div className="modal-header px-lg-7">
                    <h3 className="modal-title">{Translator.trans('JOB_DESCRIPTION_AGENT_MODAL_TITLE')}</h3>
                    <button
                        type="button"
                        className="close"
                        onClick={() => setOpenModal(false)}
                        aria-label={Translator.trans('CLOSE')}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body heavy-text p-lg-7">
                    <div>
                        <p className="heavy-text mb-7">
                            {subscribeToProfileNotificationsSuccessHeadingIcon}
                            {subscribeToProfileNotificationsSuccessHeading}
                        </p>

                        {weWillNotifyYouAboutNewsText}
                        {weWillNotifyYouAboutNewsText2}

                        {features.links_to_profile_gallery_on_profile && atmoskop.isUserLoggedIn &&
                            <p className="my-8">
                                <a
                                    href={routes['front.profileGallery.gallery']}
                                    className="btn btn-primary"
                                >{Translator.trans('LINK_TO_PROFILE_GALLERY_ON_PROFILE')}</a>
                            </p>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SubscribeToProfileNotificationsSuccessModal;
