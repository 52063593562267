// @flow

export type CookiesConsentCategories = {
    ad: string[];
    analytics: string[];
    functionality: string[];
    necessary: string[];
    personalization: string[];
};

// exports object defined in templates/scripts/cookiesConsentCategories.html.twig
declare var cookiesConsentCategories: CookiesConsentCategories;
cookiesConsentCategories = window.cookiesConsentCategories;
export default cookiesConsentCategories;