// @flow
import $ from 'jquery'; // jQuery is needed by Bootstrap
import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import type { AtmoskopGlobalVariableType } from './baseTypes';
import './multilineEllipsisSetup';
import CompanySearchForSerp from './components/CompanySearchForSerp';
import onDomReady from './utilities/onDomReady';
import features from './utilities/features';
import { initLmcCookiesConsentManager } from './cookies/initLmcCookiesConsentManager';
import { handleUncaughtError } from './utilities/logger';
import SubscribeToJobDescriptionsForm from './jobOpportunities/SubscribeToJobDescriptionsForm';
import routes from './utilities/routes';
import cookiesConsentCategories from './utilities/cookiesConsentCategories';

declare var atmoskop: AtmoskopGlobalVariableType;
declare var dataLayer: Object;

// register handler that will report uncaught errors into backend app
window.onerror = handleUncaughtError;

const newProfileSearchHtmlElement = document.querySelector('#js-new-profile-search');

if (newProfileSearchHtmlElement !== null) {
    const minimalNumberOfCharactersForSearch = parseInt(newProfileSearchHtmlElement.dataset.minimalNumberOfCharactersForSearch);

    ReactDOM.render(
        <CompanySearchForSerp
            minimalLengthToSearch={minimalNumberOfCharactersForSearch}
            dropdownMenuAdditionalClassNames={'dropdown-menu-right dropdown-menu--in-header'}
            rpsForMatejRecommendations={newProfileSearchHtmlElement.dataset.rps}
            showMatejRecommendations={features.show_recommendations_in_search}
        />,
        newProfileSearchHtmlElement,
    );
}

const createJobDescriptionAgentFormHtmlElement = document.querySelector('#js-create-job-description-agent-form');

if (createJobDescriptionAgentFormHtmlElement !== null) {
    ReactDOM.render(
        <SubscribeToJobDescriptionsForm
            isUserLoggedIn={atmoskop.isUserLoggedIn}
            majorRequestData={JSON.parse(createJobDescriptionAgentFormHtmlElement.dataset.majorRequestData)}
            requestRoute={routes['front.job_description.create_agent']}
            setError={() => {}}
            termsModalId={'createAgentFormTermsModal'}
        />,
        createJobDescriptionAgentFormHtmlElement,
    );
}

window.addEventListener('DOMContentLoaded', function () {
    if (features.cookie_consent_bar) {
        initLmcCookiesConsentManager(cookiesConsentCategories);
    }
});

onDomReady(() => {
    document.getElementById('js-login-dropdown-menu')?.addEventListener('click', (event: Event) => {
        event.stopPropagation();
    });

    document.querySelectorAll('input[type=email]').forEach((element: any) => {
        if (!(element instanceof HTMLInputElement)) {
            throw new Error('Element found by "input" selector is not an input. This should never happen.');
        }
        element.addEventListener('input', () => {
            element.value = element.value.trim();
        });
    });

    [...document.getElementsByClassName('js-logout-link')].forEach(link => {
        link.addEventListener('click', () => {
            dataLayer.push({
                'event': 'user.logout',
                'eventCategory': 'user',
                'eventAction': 'user.logout',
                'eventLabel': atmoskop.userId,
                'eventValue': 0,
            });
        });
    });

    if (!atmoskop.isUserLoggedIn && document.getElementById('subscribeToCompanyProfileNotificationsModal') != null) {
        document.querySelector('[data-target="#subscribeToCompanyProfileNotificationsModal"]')
            ?.addEventListener('click', (event: Event) => {
                dataLayer.push({
                    'event': 'user_login_view',
                    'eventCategory': 'user',
                    'eventAction': 'user_login_view',
                    'eventLabel': '-',
                    'eventValue': 0,
                    'eventNonInteraction': false,
                });
            });
    }
});
