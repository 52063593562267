// @flow

// Inspired by: https://tobiasahlin.com/blog/move-from-jquery-to-vanilla-javascript/#document-ready
export default function onDomReady(callback: () => void) {
    if (document.readyState !== 'loading') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}
