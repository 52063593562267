// @flow
import React, { useState } from 'react';
import Translator from '../utilities/translatorGlobal';
import type { AtmoskopGlobalVariableType } from '../baseTypes';
import SubscribeToProfileNotificationsSuccessModal from './SubscribeToProfileNotificationsSuccessModal';
import axios from 'axios';
import pushGANonInteractionEvent from '../analytics/pushGANonInteractionEvent';
import Icon from '../utilities/icon';

declare var atmoskop: AtmoskopGlobalVariableType;
declare var dataLayer: Object;

type Props = {|
    isUserLoggedIn: boolean,
    majorRequestData: Object,
    requestRoute: string,
    setError: (hasError: boolean) => void,
    termsModalId: string,
|}

type SubscribeToJobDescriptionsResponseData = {|
    isSubscribedSuccessfully: boolean,
|}

const SubscribeToJobDescriptionsForm = (props: Props) => {
    const [email, setEmail] = useState<string>(atmoskop.userEmail ?? '');
    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [disabledControls, setDisabledControls] = useState<boolean>(false);

    const requestData = {
        ...props.majorRequestData,
        email,
    };
    const clickedOnSubscribeButtonOnProfileJobDescriptions = props.majorRequestData.profileUrlIdentifier != null;

    const onInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };

    const onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisabledControls(true);

        axios
            .post(
                props.requestRoute,
                requestData,
            )
            .then(({ data }: { data: SubscribeToJobDescriptionsResponseData }) => {
                setSubscribed(data.isSubscribedSuccessfully);

                if (clickedOnSubscribeButtonOnProfileJobDescriptions) {
                    pushGANonInteractionEvent(
                        'subscribeToJobDescriptionsFromJobsOpportunitiesTabOnProfile',
                        'subscribeSuccessful',
                        props.majorRequestData.profileUrlIdentifier,
                    );
                }

                dataLayer.push({
                    'event': 'user_login_sent-link-to-the-email',
                    'eventCategory': 'user',
                    'eventAction': 'user_login_sent-link-to-the-email',
                    'eventLabel': '-',
                    'eventValue': 0,
                    'eventNonInteraction': false,
                });
            })
            .catch(() => {
                props.setError(true);
            });
    };

    if (props.isUserLoggedIn && subscribed && !clickedOnSubscribeButtonOnProfileJobDescriptions) {
        document.getElementById('nabidky-prace-agent-text')?.classList.add('d-none');

        return (
            <>
                <Icon name="tick" additionalClassName="icon--larger display-4 text-success mb-0" />
                <p className="h3 mb-4">{Translator.trans('SUBSCRIBE_TO_JOB_DESCRIPTIONS_SUCCESS_HEADING')}</p>
                <p className="mb-5">
                    {Translator.trans('SUBSCRIBE_TO_JOB_DESCRIPTIONS_SUCCESS_PARAGRAPH_WITHOUT_ADDRESS')}
                </p>
            </>
        );
    }

    return (
        <>
            <form onSubmit={onFormSubmit}>
                <div className="form-row">
                    {!props.isUserLoggedIn &&
                        <div className="col-12 col-md-7">
                            <label className="sr-only" htmlFor="subscribe-to-job-descriptions-email">
                                {Translator.trans('ENTER_YOUR_EMAIL')}
                            </label>
                            <input
                                id="subscribe-to-job-descriptions-email"
                                type="email"
                                required={true}
                                disabled={disabledControls}
                                className="form-control"
                                placeholder={Translator.trans('ENTER_YOUR_EMAIL')}
                                onInput={onInputChange}
                            />
                        </div>
                    }
                    <div className="col-12 col-md-5 mt-7 mt-md-0">
                        <button type="submit" className="btn btn-primary" disabled={disabledControls}>
                            {Translator.trans('SUBSCRIBE_TO_NEW_REVIEWS_SUBMIT')}
                        </button>
                    </div>
                </div>
            </form>

            {subscribed &&
                <SubscribeToProfileNotificationsSuccessModal
                    email={email}
                    isModalOpen={subscribed}
                />
            }

            {!props.isUserLoggedIn &&
                <p className="font-size-sm text-muted px-md-3 mt-4">
                    {Translator.trans('SUBSCRIBE_TO_PROFILE_NOTIFICATIONS_PERSONAL_DATA_1')}&nbsp;
                    <a href={'#' + props.termsModalId} data-toggle="modal">
                        {Translator.trans('SHOW_MORE')}
                    </a>
                    &nbsp;|&nbsp;
                    <a href={Translator.trans('TERMS_OF_SERVICE_URL', {}, 'url')} target="_blank">
                        {Translator.trans('ATMOSKOP_TERMS_OF_SERVICE', { 'siteName': atmoskop.siteName })}
                    </a>
                </p>
            }
        </>
    );
};

export default SubscribeToJobDescriptionsForm;