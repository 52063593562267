// @flow
import MultiClamp from 'multi-clamp';
import onDomReady from './utilities/onDomReady';

onDomReady(() => {
    [...document.getElementsByClassName('js-multiline-ellipsis')].forEach(element => {
        const linesCount = element.dataset.linesCount;

        new MultiClamp(element, {
            ellipsis: '...',
            clamp: linesCount,
            disableCssClamp: true, // Chrome does not hide accented characters properly when using native CSS clamp
        });
    });
});
